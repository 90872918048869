<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="8">
            <div class="form-row justify-content-end">
              <div class="col-md-6">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Título"
                  v-model="filters.title"
                />
              </div>
              <div class="col-md-1">
                <button
                  @click="getData()"
                  style="padding: 14px !important"
                  type="button"
                  class="btn btn-secondary btn-block"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
          </b-col>
        </b-form-row>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(area)="{ item }">
          <span v-if="item.area">
            {{ item.area.name }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              :id="`customer-${data.item.uuid}-delete-icon`"
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              :id="`customer-${data.item.uuid}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="modalEdit(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="">
              Exibindo {{ rowsTable }} de {{ perPage }} com total de
              {{ totalRows }} resultados
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="getData()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
        id="modal-create-salesforce"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
      >
        <form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-6 col-12">
              <label for="">
                Nome
                <i class="text-danger bi bi-record-circle"></i>
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.name"
                  :class="{ 'is-invalid': $v.item.name.$error }"
                />
              </div>
            </div>

            <div class="col-md-6 col-12">
              <label for="">
                Apelido
                <i class="text-danger bi bi-record-circle"></i>
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.alias"
                  :class="{ 'is-invalid': $v.item.alias.$error }"
                />
              </div>
            </div>

            <div class="col-md-8">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Área
              </label>
              <div class="form-group mb-2">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.area"
                  placeholder="Digite o título"
                  :options="optionsAreas"
                  :class="{ 'is-invalid': $v.item.area.$error }"
                  @search="fetchAreas"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-4 col-12">
              <label for="">
                Status
                <i class="text-danger bi bi-record-circle"></i>
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  :searchable="false"
                  item-text="title"
                  item-value="code"
                  placeholder="Status"
                  v-model="item.status"
                  :options="optionsStatus"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                />
              </div>
            </div>

            <div class="col-12">
              <label for=""> Campanha </label>
              <div class="form-group">
                <b-form-input v-model="item.campaign" />
              </div>
            </div>
            <!-- Octadesk -->
            <div class="col-12">
              <label for=""> Octadesk </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.octadesk"
                  maxlength="24"
                  :class="{ 'is-invalid': $v.item.octadesk.$error }"
                  placeholder="Digite até 24 caracteres"
                />
                <small class="text-muted"> Máximo de 24 caracteres. </small>
              </div>
            </div>
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-2">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-salesforce')"
                class="btn btn-light btn-block"
              >
                Fechar
              </button>
            </div>

            <div class="col-md-4">
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BModal,
  BFormGroup,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  components: {
    BCard,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
  },
  data() {
    return {
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      optionsAreas: [],
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "id",
          label: "ID",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
          thStyle: "width: 100px",
        },
        {
          key: "products",
          label: "Produtos",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "contests",
          label: "Concursos",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "area",
          label: "Área",
          thClass: "text-center",
        },
        {
          key: "alias",
          label: "Apelido",
          sortable: false,
          thClass: "text-center",
        },
        {
          key: "octadesk",
          label: "Octadesk",
          sortable: false,
          thClass: "text-center",
        },
        {
          key: "campaign",
          label: "Campanha",
          sortable: true,
          thClass: "text-center",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
        alias: "",
        status: "",
        area: "",
        octadesk: "",
        campaign: "",
      },
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
      btnSearch: false,
      filters: {
        title: "",
      },
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      alias: {
        required,
      },
      area: {
        required,
      },
      status: {
        required,
      },
      octadesk: {
        maxLength: maxLength(24),
      },
    },
  },
  mounted() {
    this.getData();

    this.$root.$on("open:modal-create-salesforce", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Sales force";
      this.$bvModal.show("modal-create-salesforce");
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.clearForm();
    });
  },
  computed: {
    ...mapState("Area", ["searchAreas"]),
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-salesforce");
  },
  methods: {
    async fetchAreas(term) {
      if (term.length > 2) {
        this.$store.dispatch("Area/search", term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();
      this.$store.dispatch("SalesForce/show", uuid).then((data) => {
        this.item = data;
        this.uuid = this.item.uuid;
        this.$bvModal.show("modal-create-salesforce");
      });
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("SalesForce/create", this.item)
            .then(() => {
              this.notifyDefault("success");
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-salesforce");
                this.clearForm();
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("SalesForce/update", { uuid: this.uuid, data: this.item })
            .then(() => {
              this.getData();

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-salesforce");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        name: "",
        area: "",
        status: "",
        octadesk: "",
      };
    },
    async getData() {
      this.$store
        .dispatch("SalesForce/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: this.filters,
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("SalesForce/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
};
</script>
