var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-form-row",
                { staticClass: "justify-content-between" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-none d-sm-block align-items-center justify-content-left",
                      attrs: { cols: "4", md: "2" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "title",
                          required: "",
                          options: _vm.perPageOptions,
                          clearable: false,
                        },
                        on: { input: _vm.setPerPageSelected },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { cols: "12", md: "8" } }, [
                    _c("div", { staticClass: "form-row justify-content-end" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filters.title,
                              expression: "filters.title",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "search", placeholder: "Título" },
                          domProps: { value: _vm.filters.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.filters,
                                "title",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "col-md-1" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary btn-block",
                            staticStyle: { padding: "14px !important" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.getData()
                              },
                            },
                          },
                          [_c("i", { staticClass: "bi bi-search" })]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-filtered-text": "Nenhum registro encontrado",
              "empty-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function (data) {
                  return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                },
              },
              {
                key: "cell(area)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.area
                      ? _c("span", [_vm._v(" " + _vm._s(item.area.name) + " ")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: {
                            id: "customer-" + data.item.uuid + "-delete-icon",
                            icon: "TrashIcon",
                            size: "16",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(data.item.uuid)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: {
                            id: "customer-" + data.item.uuid + "-edit-icon",
                            icon: "EditIcon",
                            size: "16",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.modalEdit(data.item.uuid)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "mx-2 mb-2 mt-2 paginate-area" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("span", {}, [
                        _vm._v(
                          " Exibindo " +
                            _vm._s(_vm.rowsTable) +
                            " de " +
                            _vm._s(_vm.perPage) +
                            " com total de " +
                            _vm._s(_vm.totalRows) +
                            " resultados "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "my-1",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          limit: 10,
                          "first-number": "",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.getData()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronLeftIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "next-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-create-salesforce",
                "modal-class": "modal-create",
                title: _vm.titleModal,
                centered: "",
                "hide-footer": "",
              },
            },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitCreate.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-6 col-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Nome "),
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("b-form-input", {
                            class: { "is-invalid": _vm.$v.item.name.$error },
                            model: {
                              value: _vm.item.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-6 col-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Apelido "),
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("b-form-input", {
                            class: { "is-invalid": _vm.$v.item.alias.$error },
                            model: {
                              value: _vm.item.alias,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "alias", $$v)
                              },
                              expression: "item.alias",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Área "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-2" },
                        [
                          _c(
                            "v-select",
                            {
                              class: { "is-invalid": _vm.$v.item.area.$error },
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título",
                                options: _vm.optionsAreas,
                              },
                              on: { search: _vm.fetchAreas },
                              model: {
                                value: _vm.item.area,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "area", $$v)
                                },
                                expression: "item.area",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-4 col-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Status "),
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-select", {
                            class: { "is-invalid": _vm.$v.item.status.$error },
                            attrs: {
                              label: "title",
                              searchable: false,
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Status",
                              options: _vm.optionsStatus,
                            },
                            model: {
                              value: _vm.item.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "status", $$v)
                              },
                              expression: "item.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Campanha "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("b-form-input", {
                            model: {
                              value: _vm.item.campaign,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "campaign", $$v)
                              },
                              expression: "item.campaign",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Octadesk "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid": _vm.$v.item.octadesk.$error,
                            },
                            attrs: {
                              maxlength: "24",
                              placeholder: "Digite até 24 caracteres",
                            },
                            model: {
                              value: _vm.item.octadesk,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "octadesk", $$v)
                              },
                              expression: "item.octadesk",
                            },
                          }),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(" Máximo de 24 caracteres. "),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom",
                    },
                    [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-block",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.$bvModal.hide(
                                  "modal-create-salesforce"
                                )
                              },
                            },
                          },
                          [_vm._v(" Fechar ")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: _vm.textSubmit,
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }